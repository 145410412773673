import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import './IntInput.css';

export type IntInputProps = {
  className?: string;
  innerClassName?: string;
  inputClassName?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;
};

const IntInput: FC<IntInputProps> = ({
  className,
  innerClassName,
  inputClassName,

  formik,
  name,
  label,
  placeholder,
  disabled,
  showError = true,
}) => {
  function incrementValue(delta = +1) {
    formik.setFieldValue(
      name,
      Math.max(1, (formik.getFieldProps(name).value ?? 0) + delta)
    );
  }

  const buttonClass = 'border-black border-opacity-30 h-full px-2 py-2';
  return (
    <div
      className={clsx(className, 'IntInput-wrapper', disabled && 'opacity-50')}
    >
      {label && (
        <label htmlFor={name} className="block mb-0 flex">
          {label}
        </label>
      )}
      <div
        className={clsx(
          innerClassName,
          `IntInput-input flex gap-2 w-full items-center           
          rounded border border-black border-opacity-30
          shadow-sm bg-white`,
          label && 'mt-1',
          disabled ? '' : 'hover:bg-background-avg'
        )}
      >
        <button
          className={clsx(buttonClass, 'border-r')}
          type="button"
          onClick={() => incrementValue(-1)}
        >
          <MinusIcon className="w-4 h-4 text-gray-300" />
        </button>
        <input
          id={name}
          type="number"
          {...formik.getFieldProps(name)}
          value={formik.getFieldProps(name).value ?? 1}
          className={clsx(inputClassName, 'block w-full min-w-8')}
          placeholder={placeholder}
          disabled={disabled}
        />
        <button
          className={clsx(buttonClass, 'border-l')}
          type="button"
          onClick={() => incrementValue()}
        >
          <PlusIcon className="w-4 h-4 text-gray-300" />
        </button>
      </div>

      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error text-sm">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default IntInput;
