import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import IntInput from '@/components/form/IntInput';
import { useIsMobile } from '@/lib';
import { formatPrice } from '@/lib/currency';
import {
  entryIsValid,
  ShoppingBasketContext,
  ShoppingBasketEntry,
} from '@/lib/shoppingBasket';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';

import { Price } from '../../../../graphql-types';

const ProductRow: FC<{
  item: ShoppingBasketEntry;
  formik: any;
  idx: number;
}> = ({ item, formik, idx }) => {
  const isMobile = useIsMobile();

  const [showResellers, setShowResellers] = useState(false);

  return (
    <div
      className={clsx('grid gap-4 items-center w-full')}
      style={{
        gridTemplateColumns: isMobile
          ? '3.5rem 1fr auto 1.5rem'
          : '3.5rem auto 128px 128px 1.5rem',
      }}
    >
      <GatsbyImage
        className="row-start-1 col-start-1 lg:row-span-2 w-14 h-14"
        alt={item.product.name}
        image={getImage(item.product.image as any)}
        objectFit="contain"
      />
      <Link
        className={clsx(
          'row-start-1 col-start-2 col-span-3',
          'lg:row-start-1 lg:col-start-2 lg:col-span-2',
          'self-start text-base text-gray-500 font-semibold'
        )}
        to={item.product.path}
      >
        {item.product.name}
      </Link>

      <div
        className={clsx(
          'row-start-2 col-start-1 col-span-2',
          'lg:row-start-2 lg:col-start-2 lg:col-span-2',
          'text-base font-semibold text-red lg:-mt-2'
        )}
      >
        {formatPrice(
          item.product.averageMinPrice.value,
          item.product.averageMinPrice.currency,
          false,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        )}
        {' - '}
        {formatPrice(
          item.product.averageMaxPrice.value,
          item.product.averageMaxPrice.currency,
          false,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        )}
      </div>

      <div
        className={clsx(
          'row-start-2 col-start-3',
          'lg:row-start-1 lg:col-start-4 lg:row-span-2',
          'flex justify-end'
        )}
      >
        <IntInput
          className="w-32"
          innerClassName="h-10"
          inputClassName="font-semibold text-center"
          formik={formik}
          name={`items.[${idx}].quantity`}
        />
      </div>

      <div
        className={clsx(
          'row-start-2 col-start-4',
          'lg:row-start-1 lg:col-start-5 lg:row-span-2',
          'flex justify-end'
        )}
      >
        <button
          className="bg-primary-300 text-white"
          type="button"
          onClick={() => {
            const newItems = [...formik.values.items];
            newItems.splice(idx, 1);

            formik.setFieldValue('items', newItems);
          }}
        >
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <button
        className={clsx(
          'row-start-3 col-start-1 col-span-4',
          'lg:row-start-3 lg:col-start-1 lg:col-span-5',
          'text-gray-500 opacity-75 flex text-left'
        )}
        type="button"
        onClick={() => setShowResellers((it) => !it)}
      >
        {showResellers ? (
          <ChevronDownIcon className="h-5 w-5 text-primary-300 mr-2" />
        ) : (
          <ChevronRightIcon className="h-5 w-5 text-primary-300 mr-2" />
        )}
        Cerut de la {item.resellers.length}
        {item.resellers.length === 1 ? ' furnizor' : ' furnizori'}
      </button>

      {showResellers && (
        <div className="col-span-4 lg:col-span-5">
          {item.resellers.map((reseller) => (
            <div key={reseller}>{reseller}</div>
          ))}
        </div>
      )}
    </div>
  );
};

const ProductsQuotePage: FC<PageProps> = () => {
  const { items, updateEntries, removeEntry } = useContext(
    ShoppingBasketContext
  );

  const formik = useFormik({
    initialValues: { items: [] as ShoppingBasketEntry[] },
    onSubmit: () => null,
  });

  useEffect(() => {
    if (!formik.dirty) {
      formik.resetForm({ values: { items: items.filter(entryIsValid) } });
      // remove broken items
      items
        .filter((entry) => !entryIsValid(entry))
        .forEach((brokenItem) => {
          removeEntry(brokenItem).catch(() => {
            // n ai csf
          });
        });
    }
  }, [items]);

  useEffect(() => {
    if (formik.dirty) {
      updateEntries(formik.values.items);
    }
  }, [formik.values.items]);

  const summary = useMemo(
    () => ({
      resellers: Object.entries<ShoppingBasketEntry[]>(
        formik.values.items
          .flatMap((item) =>
            item.resellers.map((reseller) => ({ reseller, item }))
          )
          .reduce(
            (acc, it) => ({
              ...acc,
              [it.reseller]: [...(acc[it.reseller] ?? []), it.item],
            }),
            {}
          )
      )
        .map<[string, { count: number; sum: Price }]>(
          ([reseller, itemsFromReseller]) => [
            reseller,
            {
              count: itemsFromReseller.length,
              sum: {
                value: itemsFromReseller
                  .map((it) => it.quantity * it.product.averageMaxPrice.value)
                  .reduce((a, b) => a + b, 0),
                currency: itemsFromReseller[0].product.averageMaxPrice.currency,
              },
            },
          ]
        )
        .sort((a, b) => {
          if (a[0] === b[0]) return 0;
          if (a[0] === 'OTHER') return 1;
          if (b[0] === 'OTHER') return -1;

          return a[0].localeCompare(b[0]);
        }),
      total: {
        value: formik.values.items
          .map((it) => it.quantity * it.product.price.value)
          .reduce((a, b) => a + b, 0),
        averageMin: formik.values.items
          .map(
            (it) =>
              it.quantity *
              (it.product.averageMinPrice?.value ?? it.product.price.value)
          )
          .reduce((a, b) => a + b, 0),
        averageMax: formik.values.items
          .map(
            (it) =>
              it.quantity *
              (it.product.averageMaxPrice?.value ?? it.product.price.value)
          )
          .reduce((a, b) => a + b, 0),
        currency: formik.values.items.length
          ? formik.values.items[0].product.price.currency
          : 'EUR',
      },
    }),
    [formik.values.items]
  );

  return (
    <Layout fsoHeader minimalFooter mainClassName="relative min-h-screen">
      <SEO
        url="ro/oferta-mea"
        title="Oferta mea"
        description=""
        disableIndex
        lang="ro"
      />
      <div
        className="container px-4 m-auto lg:grid gap-10"
        style={{ gridTemplateColumns: 'auto 415px' }}
      >
        <section className="pt-6 pb-7">
          <div className="font-semibold text-xl text-primary-300 mb-5">
            Cerere de oferta
          </div>

          {!formik.values.items.length
            ? 'Oferta ta este goala.'
            : formik.values.items.map((item, idx, self) => (
                <Fragment key={item.product.code}>
                  <ProductRow formik={formik} item={item} idx={idx} />
                  {idx < self.length - 1 && (
                    <hr className="mt-4 mb-6 w-full border-t border-gray-250" />
                  )}
                </Fragment>
              ))}
        </section>

        <section className="self-start bg-gray-250 pt-6 pb-7 px-4 -mx-4 lg:mx-0 lg:mt-18">
          <div className="font-semibold text-xl text-primary-300 mb-5">
            Sumar oferta
          </div>

          {summary.resellers.map(([reseller, stats]) => (
            <div key={reseller} className="flex items-center w-full py-2 gap-1">
              <div className="font-semibold">
                {reseller === 'OTHER' ? 'Alt furnizor' : reseller}
              </div>
              <div className="whitespace-nowrap">
                ({stats.count} {stats.count === 1 ? ' Produs' : ' Produse'})
              </div>
              <div className="flex-1 text-right font-semibold">
                {formatPrice(stats.sum.value, stats.sum.currency)}
              </div>
            </div>
          ))}

          <hr className="mt-4 mb-6 w-full border-t border-gray-500" />

          <div className="flex items-center w-full gap-1">
            <div className="reseller">Suma totala</div>
            <div className="flex-1 text-right font-semibold text-red">
              {formatPrice(
                summary.total.averageMin,
                summary.total.currency,
                false,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )}
              {' - '}
              {formatPrice(
                summary.total.averageMax,
                summary.total.currency,
                false,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )}
            </div>
          </div>

          <section className="bg-primary-100 bg-opacity-5 text-primary mt-4 px-4 py-2 flex gap-3">
            <div className="flex items-center justify-center">
              <InformationCircleIcon className="h-5 w-5" />
            </div>
            <div>
              <div>Pret Recomndat de Producator</div>
              <div className="font-semibold">
                {formatPrice(summary.total.value, summary.total.currency)}
              </div>
            </div>
          </section>

          <section className="mt-4 py-2">
            * Intervalul de pret este aproximat din date istorice. Pentru oferte
            complexe, discounturile pot depasi semnificativ pretul afisat.
          </section>

          <Link
            id="beginCheckout"
            className="mt-7 w-full bg-yellow font-semibold flex items-center justify-center px-4 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base"
            to="/ro/oferta-mea/checkout/"
          >
            Finalizeaza cererea de oferta
          </Link>
        </section>
      </div>
    </Layout>
  );
};

export default ProductsQuotePage;
